import './App.css';
import logo from './logo.png';

function App() {
  return (
    <div className="wrapper">
      <div className="container">
        <div className="brand-identity">
          <img src={logo} alt="KAG" />
        </div>
        <div className="text-container">
          <div className="under-construction-text">
            UNDER CONSTRUCTION
          </div>
          <a className="email-text" href="mailto:enis@kag-ks.com">
            enis@kag-ks.com
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
